import React from 'react';

const Dashboard = React.lazy(() => import('./_pages/Dashboard'));
const Page403 = React.lazy(() => import('./_pages/Page403'));

const EditProfile = React.lazy(() => import('./_pages/Auth/EditProfile'));

//Support
const ListTicket = React.lazy(() => import('./_pages/Support/Ticket/ListTicket'));
const AddTicket = React.lazy(() => import('./_pages/Support/Ticket/AddTicket'));
const EditTicket = React.lazy(() => import('./_pages/Support/Ticket/EditTicket'));

//Statistics
const Statistics = React.lazy(() => import('./_pages/Analytics/Statistics'));
const ChartBuilder = React.lazy(() => import('./_pages/Analytics/ChartBuilder'));
const Favorites = React.lazy(() => import('./_pages/Analytics/Favorites'));
const Notifications = React.lazy(() => import('./_pages/Analytics/Notifications'));
const Users = React.lazy(() => import('./_pages/Analytics/Users'));
const ItemView = React.lazy(() => import('./_pages/Analytics/ItemView'));
const Yango = React.lazy(() => import('./_pages/Analytics/Yango'));
const SoMatchAnalytics = React.lazy(() => import('./_pages/Analytics/SoMatch'));
const PosAnalytics = React.lazy(() => import('./_pages/Analytics/Pos'));

/** Account module **/

// Users
const ListUser = React.lazy(() => import('./_pages/Account/User/ListUser'));
const EditUser = React.lazy(() => import('./_pages/Account/User/EditUser'));
const AddUser = React.lazy(() => import('./_pages/Account/User/AddUser'));
const ViewUser = React.lazy(() => import('./_pages/Account/User/ViewUser'));


// Roles
const ListRole = React.lazy(() => import('./_pages/Account/Role/ListRole'));
const RoleForm = React.lazy(() => import('./_pages/Account/Role/RoleForm'));

/** Content module **/
// Report
const ListReport = React.lazy(() => import('./_pages/Content/Report/ListReport'));
const ListPost = React.lazy(() => import('./_pages/Content/Post/ListPost'));

/** Subscription module **/
// SUbscription Product
const ListProduct = React.lazy(() => import('./_pages/Subscription/Product/ListProduct'));
const AddProduct = React.lazy(() => import('./_pages/Subscription/Product/AddProduct'));
const EditProduct = React.lazy(() => import('./_pages/Subscription/Product/EditProduct'));

/** System module **/

// Client apps
const ListClientApp = React.lazy(() => import('./_pages/System/ClientApp/ListClientApp'));
const EditClientApp = React.lazy(() => import('./_pages/System/ClientApp/EditClientApp'));

// Notification providers
const ListNotificationProvider = React.lazy(() => import('./_pages/System/NotificationProvider/ListNotificationProvider'));
const EditNotificationProvider = React.lazy(() => import('./_pages/System/NotificationProvider/EditNotificationProvider'));


// Devices
const ListDevice = React.lazy(() => import('./_pages/System/Device/ListDevice'));

// App Configurations
const ListAppConfigurations = React.lazy(() => import('./_pages/System/AppConfigurations/ListAppConfigurations/ListAppConfigurations'));
const EditAppConfigurations = React.lazy(() => import('./_pages/System/AppConfigurations/EditAppConfigurations/EditAppConfigurations'));
const AddAppConfigurations = React.lazy(() => import('./_pages/System/AppConfigurations/AddAppConfigurations/AddAppConfigurations'));


// Notifications
const ListNotification = React.lazy(() => import('./_pages/System/Notification/ListNotification'));
const ViewNotification = React.lazy(() => import('./_pages/System/Notification/ViewNotification'));
const SendNotification = React.lazy(() => import('./_pages/System/Notification/SendNotification'));
//const SendNotificationToFollowers = React.lazy(() => import('./_pages/System/Notification/SendNotificationToFollowers'));

/** Data module **/
//Categories
const ListCategory = React.lazy(() => import('./_pages/Data/Category/ListCategory'));
const CategoryForm = React.lazy(() => import('./_pages/Data/Category/CategoryForm'));

//Attributes
const ListAttribute = React.lazy(() => import('./_pages/Data/Attribute/ListAttribute'));
const AttributeForm = React.lazy(() => import('./_pages/Data/Attribute/AttributeForm'));


//Section templates
const ListSectionTemplate = React.lazy(() => import('./_pages/Data/SectionTemplate/ListSectionTemplate'));

//Tags
const ListTag = React.lazy(() => import('./_pages/Data/Tag/ListTag'));
const AddTag = React.lazy(() => import('./_pages/Data/Tag/AddTag'));
const EditTag = React.lazy(() => import('./_pages/Data/Tag/EditTag'));

//Item
const ListItem = React.lazy(() => import('./_pages/Data/Item/ListItem'));
const ListFeaturedItem = React.lazy(() => import('./_pages/Data/Item/ListFeaturedItem'));
const ViewItem = React.lazy(() => import('./_pages/Data/Item/ViewItem'));
const EditItem = React.lazy(() => import('./_pages/Data/Item/EditItem'));
const AddItem = React.lazy(() => import('./_pages/Data/Item/AddItem'));
const ImportItem = React.lazy(() => import('./_pages/Data/Item/ImportItem'));
const ExportItem = React.lazy(() => import('./_pages/Data/Item/ExportItem'));

//Media
const ListImage = React.lazy(() => import('./_pages/Data/Media/ListImage'));
const ListGallery = React.lazy(() => import('./_pages/Data/Media/ListGallery'));


//Importer
const ImporterItem = React.lazy(() => import('./_pages/Importer/ImportItem'));
const ImporterMedia = React.lazy(() => import('./_pages/Importer/ImportMedia'));
const ListImportedFile = React.lazy(() => import('./_pages/Importer/ListImportedFile'));
const ViewImportedFile = React.lazy(() => import('./_pages/Importer/ViewImportedFile'));

/** Pronostics module **/

// GameTypes
const ListGameType = React.lazy(() => import('./_pages/Pronostic/GameType/ListGameType'));
const EditGameType = React.lazy(() => import('./_pages/Pronostic/GameType/EditGameType'));
const AddGameType = React.lazy(() => import('./_pages/Pronostic/GameType/AddGameType'));

// BetTypes
const ListBetType = React.lazy(() => import('./_pages/Pronostic/BetType/ListBetType'));
const EditBetType = React.lazy(() => import('./_pages/Pronostic/BetType/EditBetType'));
const AddBetType = React.lazy(() => import('./_pages/Pronostic/BetType/AddBetType'));

// Tournaments
const ListTournament = React.lazy(() => import('./_pages/Pronostic/Tournament/ListTournament'));
const EditTournament = React.lazy(() => import('./_pages/Pronostic/Tournament/EditTournament'));
const AddTournament = React.lazy(() => import('./_pages/Pronostic/Tournament/AddTournament'));
const ImportTournament = React.lazy(() => import('./_pages/Pronostic/Importer/ImportTournament'));
const ListImportedLeagues = React.lazy(() => import('./_pages/Pronostic/Importer/ListImportedLeagues'));

// Games
const ListGame = React.lazy(() => import('./_pages/Pronostic/Game/ListGame'));
const EditGame = React.lazy(() => import('./_pages/Pronostic/Game/EditGame'));
const AddGame = React.lazy(() => import('./_pages/Pronostic/Game/AddGame'));

//Participants
const ListParticipant = React.lazy(() => import('./_pages/Pronostic/Participant/ListParticipant'));
const EditParticipant = React.lazy(() => import('./_pages/Pronostic/Participant/EditParticipant'));
const AddParticipant = React.lazy(() => import('./_pages/Pronostic/Participant/AddParticipant'));

//Participant types
const ListParticipantType = React.lazy(() => import('./_pages/Pronostic/ParticipantType/ListParticipantType'));
const AddParticipantType = React.lazy(() => import('./_pages/Pronostic/ParticipantType/AddParticipantType'));
const EditParticipantType = React.lazy(() => import('./_pages/Pronostic/ParticipantType/EditParticipantType'));

//Ranking
//const ListRanking = React.lazy(() => import('./_pages/Pronostic/Ranking/ListRanking'));


//Cities
const ListCity = React.lazy(() => import('./_pages/Address/City/ListCity'));
const AddCity = React.lazy(() => import('./_pages/Address/City/AddCity'));
const EditCity = React.lazy(() => import('./_pages/Address/City/EditCity'));

//Districts
const ListDistrict = React.lazy(() => import('./_pages/Address/District/ListDistrict'));
const AddDistrict = React.lazy(() => import('./_pages/Address/District/AddDistrict'));
const EditDistrict = React.lazy(() => import('./_pages/Address/District/EditDistrict'));

//Payment
const ListPaymentMethod = React.lazy(() => import('./_pages/Payment/PaymentMethod/ListPaymentMethod'));
const ListInvoice = React.lazy(() => import('./_pages/Payment/Invoice/ListInvoice'));
//const ListSubscription = React.lazy(() => import('./_pages/Payment/Invoice/ListSubscription'));

/** Pos module **/
//Categories
const ListPosCategory = React.lazy(() => import('./_pages/Pos/Category/ListCategory/ListCategory'));
const PosCategoryForm = React.lazy(() => import('./_pages/Pos/Category/CategoryForm'));

//products
const ListPosProduct = React.lazy(() => import('./_pages/Pos/Product/ListProduct/ListProduct'));
const AddPosProduct = React.lazy(() => import('./_pages/Pos/Product/AddProduct/AddProduct'));
const EditPosProduct = React.lazy(() => import('./_pages/Pos/Product/EditProduct/EditProduct'));

//orders
const ListPosOrder = React.lazy(() => import('./_pages/Pos/Order/ListOrder/ListOrder'));
const ViewPosOrder = React.lazy(() => import('./_pages/Pos/Order/ViewOrder/ViewOrder'));
const EditPosOrder = React.lazy(() => import('./_pages/Pos/Order/EditOrder/EditOrder'));


//payment transactions
const ListPosPaymentTransaction = React.lazy(() => import('./_pages/Pos/PaymentTransaction/ListPaymentTransaction/ListPaymentTransaction'));
const ViewPosPaymentTransaction = React.lazy(() => import('./_pages/Pos/PaymentTransaction/ViewPaymentTransaction/ViewPaymentTransaction'));

//merchants
const ListMerchant = React.lazy(() => import('./_pages/Pos/Merchant/ListMerchant/ListMerchant'));

//PaymentOperators
const ListPaymentOperators = React.lazy(() => import('./_pages/Pos/PaymentOperators/ListPaymentOperators/ListPaymentOperators'));
const AddPaymentOperators = React.lazy(() => import('./_pages/Pos/PaymentOperators/AddPaymentOperators/AddPaymentOperators'));
const EditPaymentOperators = React.lazy(() => import('./_pages/Pos/PaymentOperators/EditPaymentOperators/EditPaymentOperators'));

//PaymentAccounts
const ListPaymentAccounts = React.lazy(() => import('./_pages/Pos/PaymentAccounts/ListPaymentAccounts/ListPaymentAccounts'));
const ViewPosPaymentAccounts = React.lazy(() => import('./_pages/Pos/PaymentAccounts/ViewPaymentAccount/ViewPaymentAccount'));

//const AddPaymentAccounts = React.lazy(() => import('./_pages/Pos/PaymentOperators/AddPaymentOperators/AddPaymentOperators'));
//const EditPaymentAccounts = React.lazy(() => import('./_pages/Pos/PaymentOperators/EditPaymentOperators/EditPaymentOperators'));



const routes = [
  { path: '', name: 'Dashboard', component: Dashboard },
  { path: 'analytics/dashboard', name: 'Statistics', component: Statistics, allowed_roles: ['admin', 'sysadmin', 'business_admin', 'analyst'] },
  { path: 'analytics/chartbuilder', name: 'ChartBuilder', component: ChartBuilder, allowed_roles: ['admin', 'sysadmin', 'analyst'] },
  { path: 'analytics/favorites', name: 'Favorites', component: Favorites, allowed_roles: ['admin', 'sysadmin', 'business_admin', 'analyst'] },
  { path: 'analytics/notifications', name: 'Notifications', component: Notifications, allowed_roles: ['admin', 'sysadmin', 'business_admin', 'analyst'] },
  { path: 'analytics/users', name: 'Users', component: Users, allowed_roles: ['admin', 'sysadmin', 'analyst'] },
  { path: 'analytics/item_view', name: 'ItemView', component: ItemView, allowed_roles: ['admin', 'sysadmin', 'business_admin', 'analyst'] },
  { path: 'analytics/yango', name: 'Yango', component: Yango, allowed_roles: ['admin', 'sysadmin', 'business_admin', 'analyst'] },
  { path: 'analytics/somatch', name: 'SoMatch', component: SoMatchAnalytics, allowed_roles: ['admin', 'sysadmin', 'business_admin', 'analyst'] },
  { path: 'analytics/pos', name: 'Pos', component: PosAnalytics, allowed_roles: ['admin', 'sysadmin', 'business_admin', 'analyst'] },
  { path: 'system/client-apps', name: 'List Client app', component: ListClientApp, allowed_roles: ['admin', 'sysadmin'] },
  { path: 'system/client-apps/:id', name: 'Edit client app', component: EditClientApp, allowed_roles: ['admin', 'sysadmin'] },
  { path: 'system/notification-providers', name: 'List Notification provider', component: ListNotificationProvider, allowed_roles: ['admin', 'sysadmin'] },
  { path: 'system/notification-providers/edit/:id', name: 'Edit Notification provider', component: EditNotificationProvider, allowed_roles: ['admin', 'sysadmin'] },
  { path: 'system/devices', name: 'List Device', component: ListDevice, allowed_roles: ['admin', 'sysadmin'] },
  { path: 'system/notifications', name: 'List Notification', component: ListNotification, allowed_roles: ['admin', 'sysadmin', 'business_admin'] },
  { path: 'system/notifications/send', name: 'Send Notification', component: SendNotification, allowed_roles: ['admin', 'sysadmin'] },
  //  { path: 'system/notifications/send-to-followers/item/:id', name: 'SendNotificationToFollowers', component: SendNotificationToFollowers, allowed_roles:['admin','sysadmin'] },
  { path: 'system/notifications/:id', name: 'View Notification', component: ViewNotification, allowed_roles: ['admin', 'sysadmin', 'business_admin'] },

  //System => Configuration
  { path: 'system/app-configurations', name: 'App Configurations', component: ListAppConfigurations, allowed_roles: ['admin', 'sysadmin'] },
  { path: 'system/app-configurations/:id', name: 'Edit App Configuration', component: EditAppConfigurations, allowed_roles: ['admin', 'sysadmin'] },
  { path: 'system/appconfigurations/add', name: 'Add App Configuration', component: AddAppConfigurations, allowed_roles: ['admin', 'sysadmin'] },


  { path: 'account/users', name: 'List user', component: ListUser, allowed_roles: ['admin', 'sysadmin'] },
  { path: 'account/users/edit/:id', name: 'Edit user', component: EditUser, allowed_roles: ['admin', 'sysadmin'] },
  { path: 'account/users/add', name: 'Add user', component: AddUser, allowed_roles: ['admin', 'sysadmin'] },
  { path: 'account/users/:id', name: 'View user', component: ViewUser, allowed_roles: ['admin', 'sysadmin'] },

  { path: 'content/report', name: 'List report', component: ListReport, allowed_roles: ['admin', 'sysadmin'] },
  { path: 'content/posts', name: 'List post', component: ListPost, allowed_roles: ['admin', 'sysadmin'] },

  { path: 'subscription/product', name: 'List subscription products', component: ListProduct, allowed_roles: ['admin', 'sysadmin'] },
  { path: 'subscription/product/add', name: 'Add subscription products', component: AddProduct, allowed_roles: ['admin', 'sysadmin'] },
  { path: 'subscription/product/:id', name: 'Edit subscription products', component: EditProduct },

  { path: 'account/roles', name: 'List role', component: ListRole, allowed_roles: ['admin', 'sysadmin'] },
  { path: 'account/roles/add', name: 'Add role', component: RoleForm, allowed_roles: ['admin', 'sysadmin'] },
  { path: 'account/roles/:id', name: 'Edit role', component: RoleForm, allowed_roles: ['admin', 'sysadmin'] },
  { path: 'data/categories', name: 'List category', component: ListCategory, allowed_roles: ['admin', 'sysadmin'] },
  { path: 'data/categories/edit/:id', name: 'Edit category', component: CategoryForm, allowed_roles: ['admin', 'sysadmin'] },
  { path: 'data/categories/add', name: 'Add category', component: CategoryForm, allowed_roles: ['admin', 'sysadmin'] },
  { path: 'data/events/draft', name: 'List item', component: ListItem, allowed_roles: ['admin', 'sysadmin', 'business_admin'] },
  { path: 'data/events/published', name: 'List item', component: ListItem, allowed_roles: ['admin', 'sysadmin', 'business_admin'] },
  { path: 'data/places/draft', name: 'List item', component: ListItem, allowed_roles: ['admin', 'sysadmin', 'business_admin'] },
  { path: 'data/places/published', name: 'List item', component: ListItem, allowed_roles: ['admin', 'sysadmin', 'business_admin'] },
  { path: 'data/places/featured', name: 'List featured places', component: ListFeaturedItem, allowed_roles: ['admin', 'sysadmin'] },
  { path: 'data/events/featured', name: 'List featured events', component: ListFeaturedItem, allowed_roles: ['admin', 'sysadmin'] },
  { path: 'data/places/add', name: 'Add item', component: AddItem, allowed_roles: ['admin', 'sysadmin', 'business_admin'] },
  { path: 'data/events/add', name: 'Add item', component: AddItem, allowed_roles: ['admin', 'sysadmin', 'business_admin'] },
  { path: 'data/places/edit/:id/parent/:parent_id', name: 'Edit item', component: EditItem, allowed_roles: ['admin', 'sysadmin', 'business_admin'] },
  { path: 'data/events/edit/:id/parent/:parent_id', name: 'Edit item', component: EditItem, allowed_roles: ['admin', 'sysadmin', 'business_admin'] },
  { path: 'data/places/edit/:id', name: 'Edit item', component: EditItem, allowed_roles: ['admin', 'sysadmin', 'business_admin'] },
  { path: 'data/events/edit/:id', name: 'Edit item', component: EditItem, allowed_roles: ['admin', 'sysadmin', 'business_admin'] },
  { path: 'data/items/:id', name: 'View item', component: ViewItem, allowed_roles: ['admin', 'sysadmin'] },
  { path: 'data/places/import', name: 'Import item', component: ImportItem, allowed_roles: ['admin', 'sysadmin'] },
  { path: 'data/places/export', name: 'Export item', component: ExportItem, allowed_roles: ['admin', 'sysadmin'] },
  { path: 'data/attributes', name: 'List attribute', component: ListAttribute, allowed_roles: ['admin', 'sysadmin'] },
  { path: 'data/attributes/add', name: 'Add attribute', component: AttributeForm, allowed_roles: ['admin', 'sysadmin'] },
  { path: 'data/attributes/:id', name: 'Edit attribute', component: AttributeForm, allowed_roles: ['admin', 'sysadmin'] },
  { path: 'data/section_templates', name: 'List section template', component: ListSectionTemplate, allowed_roles: ['admin', 'sysadmin'] },
  { path: 'data/tags', name: 'List tag', component: ListTag, allowed_roles: ['admin', 'sysadmin'] },
  { path: 'data/tags/add', name: 'Add tag', component: AddTag, allowed_roles: ['admin', 'sysadmin'] },
  { path: 'data/tags/edit/:id', name: 'Edit tag', component: EditTag, allowed_roles: ['admin', 'sysadmin'] },
  { path: 'support/tickets', name: 'ListTicket', component: ListTicket },
  { path: 'support/tickets/add', name: 'AddTicket', component: AddTicket },
  { path: 'support/tickets/:id', name: 'EditTicket', component: EditTicket },
  { path: 'importer/import/items', name: 'Importer item', component: ImporterItem, allowed_roles: ['admin', 'sysadmin'] },
  { path: 'importer/import/media', name: 'Importer media', component: ImporterMedia, allowed_roles: ['admin', 'sysadmin'] },
  { path: 'importer/imported_files', name: 'Imported files', component: ListImportedFile, allowed_roles: ['admin', 'sysadmin'] },
  { path: 'importer/imported_files/:id', name: 'View Imported file', component: ViewImportedFile, allowed_roles: ['admin', 'sysadmin'] },
  { path: 'access_denied', name: 'Access denied', component: Page403 },
  { path: 'auth/edit-profile', name: 'EditProfile', component: EditProfile },
  { path: 'data/media/images', name: 'ListImage', component: ListImage, allowed_roles: ['admin', 'sysadmin', 'business_admin'] },
  { path: 'data/media/galleries', name: 'ListGallery', component: ListGallery, allowed_roles: ['admin', 'sysadmin', 'business_admin'] },
  { path: 'pronostic/tournaments', name: 'List tournament', component: ListTournament, allowed_roles: ['admin', 'sysadmin'] },
  { path: 'pronostic/tournaments/edit/:id', name: 'Edit tournament', component: EditTournament, allowed_roles: ['admin', 'sysadmin'] },
  { path: 'pronostic/tournaments/add', name: 'Add tournament', component: AddTournament, allowed_roles: ['admin', 'sysadmin'] },
  { path: 'pronostic/importer/tournament', name: 'Import tournament', component: ImportTournament, allowed_roles: ['admin', 'sysadmin'] },
  { path: 'pronostic/importer/logs', name: 'List imported files', component: ListImportedLeagues, allowed_roles: ['admin', 'sysadmin'] },
  { path: 'pronostic/gametypes', name: 'List game types', component: ListGameType, allowed_roles: ['admin', 'sysadmin'] },
  { path: 'pronostic/gametypes/edit/:id', name: 'Edit game type', component: EditGameType, allowed_roles: ['admin', 'sysadmin'] },
  { path: 'pronostic/gametypes/add', name: 'Add game type', component: AddGameType, allowed_roles: ['admin', 'sysadmin'] },
  { path: 'pronostic/bet_types', name: 'List bet types', component: ListBetType, allowed_roles: ['admin', 'sysadmin'] },
  { path: 'pronostic/bet_types/edit/:id', name: 'Edit bet type', component: EditBetType, allowed_roles: ['admin', 'sysadmin'] },
  { path: 'pronostic/bet_types/add', name: 'Add bet type', component: AddBetType, allowed_roles: ['admin', 'sysadmin'] },
  { path: 'pronostic/games', name: 'List game', component: ListGame, allowed_roles: ['admin', 'sysadmin'] },
  { path: 'pronostic/games/edit/:id', name: 'Edit game', component: EditGame, allowed_roles: ['admin', 'sysadmin'] },
  { path: 'pronostic/games/add', name: 'Add game', component: AddGame, allowed_roles: ['admin', 'sysadmin'] },
  { path: 'pronostic/participant_types', name: 'List participant types', component: ListParticipantType, allowed_roles: ['admin', 'sysadmin'] },
  { path: 'pronostic/participant_types/add', name: 'Add participant type', component: AddParticipantType, allowed_roles: ['admin', 'sysadmin'] },
  { path: 'pronostic/participant_types/edit/:id', name: 'Edit participant type', component: EditParticipantType, allowed_roles: ['admin', 'sysadmin'] },
  { path: 'pronostic/participants', name: 'List partiticpant', component: ListParticipant, allowed_roles: ['admin', 'sysadmin'] },
  { path: 'pronostic/participants/edit/:id', name: 'Edit participant', component: EditParticipant, allowed_roles: ['admin', 'sysadmin'] },
  { path: 'pronostic/participants/add', name: 'Add participant', component: AddParticipant, allowed_roles: ['admin', 'sysadmin'] },
  { path: 'address/cities', name: 'List cities', component: ListCity, allowed_roles: ['admin', 'sysadmin'] },
  { path: 'address/cities/add', name: 'Add city', component: AddCity, allowed_roles: ['admin', 'sysadmin'] },
  { path: 'address/cities/edit/:id', name: 'Edit city', component: EditCity, allowed_roles: ['admin', 'sysadmin'] },
  { path: 'address/districts', name: 'List districts', component: ListDistrict, allowed_roles: ['admin', 'sysadmin'] },
  { path: 'address/districts/add', name: 'Add district', component: AddDistrict, allowed_roles: ['admin', 'sysadmin'] },
  { path: 'address/districts/edit/:id', name: 'Edit district', component: EditDistrict, allowed_roles: ['admin', 'sysadmin'] },
  { path: 'payment/payment_methods', name: 'List payment methods', component: ListPaymentMethod, allowed_roles: ['admin', 'sysadmin', 'business_admin'] },
  { path: 'payment/invoices', name: 'List invoices', component: ListInvoice, allowed_roles: ['admin', 'sysadmin', 'business_admin'] },

  { path: 'pos/categories', name: 'List category', component: ListPosCategory },
  { path: 'pos/categories/edit/:id', name: 'Edit category', component: PosCategoryForm },
  { path: 'pos/categories/add', name: 'Add category', component: PosCategoryForm },
  { path: 'pos/products', name: 'List product', component: ListPosProduct },
  { path: 'pos/products/add', name: 'Add product', component: AddPosProduct },
  { path: 'pos/products/edit/:id', name: 'Edit product', component: EditPosProduct },
  { path: 'pos/goodies', name: 'List goodies', component: ListPosProduct },
  { path: 'pos/goodies/add', name: 'List goodies', component: AddPosProduct },
  { path: 'pos/goodies/edit/:id', name: 'Edit goodies', component: EditPosProduct },
  { path: 'pos/orders', name: 'List order', component: ListPosOrder, allowed_roles: ['admin', 'sysadmin', 'business_admin'] },
  { path: 'pos/orders/:id', name: 'View order', component: ViewPosOrder, allowed_roles: ['admin', 'sysadmin', 'business_admin'] },
  { path: 'pos/orders/edit/:id', name: 'Edit order', component: EditPosOrder, allowed_roles: ['admin', 'sysadmin', 'business_admin'] },
  { path: 'pos/merchants', name: 'List merchant', component: ListMerchant },
  { path: 'pos/payment_operators', name: 'List payment operators', component: ListPaymentOperators },
  { path: 'pos/payment_operators/add', name: 'Add payment operators', component: AddPaymentOperators },
  { path: 'pos/payment_operators/edit/:id', name: 'Edit payment operators', component: EditPaymentOperators },

  { path: 'pos/payment_transactions', name: 'List payment transaction', component: ListPosPaymentTransaction },
  { path: 'pos/payment_transactions/:id', name: 'View payment transaction', component: ViewPosPaymentTransaction },

  { path: 'pos/payment_accounts', name: 'List payment accounts', component: ListPaymentAccounts },
  { path: 'pos/payment_accounts/:id', name: 'View payment accounts', component: ViewPosPaymentAccounts },
  //  { path: 'payment/subscriptions', name: 'List subscriptions', component: ListSubscription,  allowed_roles:['admin','sysadmin','business_admin'] },
  ////  { path: 'pronostic/ranking', name: 'List ranking', component: ListRanking, allowed_roles:['admin','sysadmin'] },
];

export default routes;
