//Core react libs
import React, {Component} from 'react';
import { connect } from 'react-redux';

// Translation
import {getTranslate, getLanguages, getActiveLanguage } from 'react-localize-redux';

//UI components
import {
        Row, Col, Button, Progress,
        Dropdown, DropdownItem, DropdownMenu, DropdownToggle
      } from 'reactstrap';


import { layoutActions } from '../../../../../_services/actions';
import {
   ActionUpdateButton
  } from '../../../../../_components/form'


/** Sections preview block **/
class SectionsMobilePreviewAsideBlock extends Component {
  /**
  * To setup the inital state
  * @param {Object} props
  */
  constructor(props) {
      super(props);
      let show_all_attributes = [];
      let sorted_sections = [];
      let field_types = [];
      let errorBag = {'field_validation_messages':[]};
      let saved = false;
      let changed = false;
      let loading = false;
      let progress= {
        completed:0,
        total:0
      };
      if(this.props.section_data !== null){
        this.props.section_data.sections.map((section, index)=>{
            show_all_attributes.push(false)
          return true;
        });

        //Sorting by display order
        let sections = this.props.section_data.sections.slice(0);
        sorted_sections = sections.sort((a,b) => (a.mobile_display_order < b.mobile_display_order)?-1:1).map(
          function(section){
            let new_section = JSON.parse(JSON.stringify(section));
            new_section.attribute_values.sort((a,b) => (a.mobile_display_order_in_section < b.mobile_display_order_in_section)?-1:1)
            return new_section;
          }
        );

        field_types = this.props.section_data.field_types;
        errorBag = this.props.section_data.errorBag;
        progress = this.props.section_data.progress;
        saved = this.props.section_data.saved;
        changed = this.props.section_data.changed;
        loading = this.props.section_data.loading;
      }


      //To initialize the state
      this.state = {
          show_all_attributes:show_all_attributes,
          sections:sorted_sections,
          field_types:field_types,
          errorBag: errorBag,
          changed: changed,
          saved: saved,
          loading: loading,
          progress: progress,
          activeLanguage:this.props.activeLanguage,
          dropdownOpen:false
      };
  }


  /**
  * To load the data of the items when component updated
  * @param {Object} prevProps
  */
  componentDidUpdate(prevProps) {
    if(prevProps.section_data !== this.props.section_data){
      let show_all_attributes = [];
      let sorted_sections = [];
      let field_types = [];
      let errorBag = {'field_validation_messages':[]};
      let saved = false;
      let changed = false;
      let loading = false;
      let progress= {
        completed:0,
        total:0
      };

      if(this.props.section_data !== null){

        this.props.section_data.sections.map((section, index)=>{
            show_all_attributes.push(false)
          return true;
        });


        //Sorting by display order
        let sections = this.props.section_data.sections.slice(0);
        sorted_sections = sections.sort((a,b) => (a.mobile_display_order < b.mobile_display_order)?-1:1).map(
          function(section){
            let new_section = JSON.parse(JSON.stringify(section));
            new_section.attribute_values.sort((a,b) => (a.mobile_display_order_in_section < b.mobile_display_order_in_section)?-1:1)
            return new_section;
          }
        );

        field_types = this.props.section_data.field_types;
        errorBag = this.props.section_data.errorBag;
        progress = this.props.section_data.progress;
        saved = this.props.section_data.saved;
        changed = this.props.section_data.changed;
        loading = this.props.section_data.loading;
      }


      this.setState(
        {
          show_all_attributes: show_all_attributes,
          sections:sorted_sections,
          field_types:field_types,
          errorBag:errorBag,
          changed: changed,
          saved: saved,
          loading: loading,
          progress: progress,
          dropdownOpen:false
        }
      );
    }
    if(prevProps.activeLanguage !== this.props.activeLanguage){
      this.setState(
        {
          activeLanguage:this.props.activeLanguage
        }
      );
    }
  }


  /**
  * To toggle the attribute show all
  * @param {integer} tab
  */
  showAllAttributes = (section_index) =>{
    const prevState = this.state.show_all_attributes;
    const state = prevState.map((x, index) => section_index === index ? !x : false);
    this.setState({
      show_all_attributes: state,
    });
  }


  /**
  * To hide preview
  */
  hideSectionPreview = () =>{
    this.props.dispatch(layoutActions.hideSectionAsideBar());
  }

  /**
  * To toggle language dropdown
  */
  toggle=()=> {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  }

  /**
  * To render language dropdown
  */
  dropLanguages=()=> {
    const {languages, translate } = this.props;
    const { activeLanguage } = this.state;

    const languageOptions = [];
    const handleChange = (e,{code}) =>{
      this.setState({
        activeLanguage:code
      })
    };
    //languageOptions.push(  <DropdownItem key="title" header tag="div" className="text-center"><strong>{translate('Languages')}</strong></DropdownItem>);
    languages.map((language)=>{
      languageOptions.push(<DropdownItem key={language.code} active={activeLanguage === language.code}  onClick={(e) => handleChange(e, language)}>{translate(language.code)}</DropdownItem>);
      return true;
    });
    return (
      <Dropdown nav isOpen={this.state.dropdownOpen} toggle={this.toggle} >
        <DropdownToggle nav caret>
          <span><i className="far fa-globe-africa"></i></span>
        </DropdownToggle>
        <DropdownMenu right>
          {languageOptions}
        </DropdownMenu>
      </Dropdown>
    );
  }



  /**
  * To render the component
  * @returns  {Object} HTML element
  */
  render() {
    const {
      translate,
      section_data,
      alert,
      show_section_aside_bar
    } = this.props;

    const{
      sections,
      show_all_attributes,
      field_types,
      errorBag,
      progress,
      loading,
      saved,
      changed,
      activeLanguage
    } = this.state;
    return (
      <aside className="aside-section-menu">
          <div className="tab-content">
          <Row className="pl-3 pr-3">
            <Col xs="9">
              <h5 className="mt-3">{translate('Sections mobile preview')}</h5>
            </Col>
            <Col xs="1" className="navbar-nav pt-2 text-right">
            {this.dropLanguages()}
            </Col>
            <Col xs="2">
            {show_section_aside_bar &&
            <Button
            block={true}
            color="link"
            className="card-header-action btn btn-minimize p-3 text-right"
            onClick={this.hideSectionPreview}
            >
             <i className="fal fa-times"></i>
            </Button>
            }
            </Col>
          </Row>
          <div className="mt-4">
          {
            sections.map((section, section_index)=>{
              return(
                <Row key={section_index}  className="pl-3 pr-3">
                <Col xs="12">
                  <Row>
                    <Col xs="7">
                      <strong>
                        {section.translations[activeLanguage]['label']}
                      </strong>
                    </Col>
                    <Col xs="5" className="text-right">
                      {(section.mobile_display_collapsable === 1  && !show_all_attributes[section_index])&&
                        <Button
                        color="link"
                        className="card-header-action btn btn-minimize"
                        onClick={() => this.showAllAttributes(section_index)}
                        aria-controls={section.id}>
                        { translate('Show all', null, { language: activeLanguage }) }
                        </Button>
                      }
                      {(section.mobile_display_collapsable === 1  && show_all_attributes[section_index]) &&
                        <Button
                        color="link"
                        className="card-header-action btn btn-minimize"
                        onClick={() => this.showAllAttributes(section_index)}
                        aria-controls={section.id}>
                        { translate('Hide', null, { language: activeLanguage }) }
                        </Button>
                      }
                    </Col>
                  </Row>
                 {
                  section.attribute_values.map((attribute_value, attribute_index)=>{
                  return(
                  <div  key={attribute_index}>
                  {(section.mobile_display_collapsable === 0 || attribute_index === 0 || (section.mobile_display_collapsable === 1 && attribute_index > 0 && show_all_attributes[section_index])) &&
                    <Row className="mt-2">
                      {attribute_value.mobile_display_attribute_label === 1 &&
                        <Col
                        xs={(attribute_value.mobile_display_type === 'split_col')?"6":"12"}
                        className={
                        attribute_value.mobile_display_type === 'full_col'
                        ?"text-"+(attribute_value.mobile_display_alignment)
                        :"text-"+(attribute_value.mobile_display_alignment.split('_')[0])
                        }
                        >
                            {attribute_value.attribute.translations[activeLanguage]['label']}
                        </Col>
                      }
                      <Col xs={(attribute_value.mobile_display_type === 'split_col')?"6":"12"}
                      className={
                      attribute_value.mobile_display_type === 'full_col'
                      ?"text-"+(attribute_value.mobile_display_alignment)
                      :"text-"+(attribute_value.mobile_display_alignment.split('_')[1])
                      }
                      >
                          {
                            (field_types.filter(field_type =>
                            field_type.id === attribute_value.attributable.field_type_id)[0].slug === 'html_field') &&
                            <div dangerouslySetInnerHTML={{__html: attribute_value.attributable.translations[activeLanguage]['content']}}>
                            </div>
                          }
                          {
                            (field_types.filter(field_type =>
                            field_type.id === attribute_value.attributable.field_type_id)[0].slug === 'raw_text_field') &&
                            <div>
                              {attribute_value.attributable.translations[activeLanguage]['content']}
                            </div>
                          }
                          {(field_types.filter( field_type =>
                            field_type.id === attribute_value.attributable.field_type_id)[0].slug === 'two_timeinterval_field') &&
                            <div>
                              {((
                                attribute_value.attributable.time_1_from ===  null
                                && attribute_value.attributable.time_1_to ===  null
                                && attribute_value.attributable.time_2_from ===  null
                                && attribute_value.attributable.time_2_to ===  null
                              ) ||
                              (
                                attribute_value.attributable.time_1_from ===  ""
                                && attribute_value.attributable.time_1_to ===  ""
                                && attribute_value.attributable.time_2_from ===  ""
                                && attribute_value.attributable.time_2_to ===  ""
                              ))&&
                              <div>
                                {translate('Closed')}
                              </div>
                              }
                              {
                              ((attribute_value.attributable.time_1_from !==  null
                              || attribute_value.attributable.time_1_to !==  null
                              || attribute_value.attributable.time_2_from !==  null
                              || attribute_value.attributable.time_2_to !==  null
                            )&&(
                              attribute_value.attributable.time_1_from !==  ""
                              || attribute_value.attributable.time_1_to !==  ""
                              || attribute_value.attributable.time_2_from !==  ""
                              || attribute_value.attributable.time_2_to !==  "")
                              )
                              &&
                              <div>
                                <div>
                                  {attribute_value.attributable.time_1_from.substring(0,5)} &nbsp;-&nbsp;
                                  {attribute_value.attributable.time_1_to.substring(0,5)}
                                </div>
                                <div>
                                  {attribute_value.attributable.time_2_from.substring(0,5)} &nbsp;-&nbsp;
                                  {attribute_value.attributable.time_2_to.substring(0,5)}
                                </div>
                              </div>
                              }
                            </div>
                          }
                          {(field_types.filter( field_type =>
                            field_type.id === attribute_value.attributable.field_type_id)[0].slug === 'timeinterval_field') &&
                            <div>
                              {((
                                attribute_value.attributable.time_from ===  null
                                && attribute_value.attributable.time_to ===  null
                              ) ||
                              (
                                attribute_value.attributable.time_from ===  ""
                                && attribute_value.attributable.time_to ===  ""
                              ))&&
                              <div>
                                {translate('Closed')}
                              </div>
                              }
                              {
                              ((attribute_value.attributable.time_from !==  null
                              || attribute_value.attributable.time_to !==  null
                              )&&(
                              attribute_value.attributable.time_from !==  ""
                              || attribute_value.attributable.time_to !==  ""
                              ))
                              &&
                              <div>
                                <div>
                                  {attribute_value.attributable.time_from.substring(0,5)} &nbsp;-&nbsp;
                                  {attribute_value.attributable.time_to.substring(0,5)}
                                </div>
                              </div>
                              }
                            </div>


                          }
                          {(field_types.filter( field_type =>
                            field_type.id === attribute_value.attributable.field_type_id)[0].slug === 'boolean_field') &&
                            <div>
                              {(attribute_value.attributable.value)?translate('Yes'):translate('No')}
                            </div>
                          }

                          {(field_types.filter( field_type =>
                            field_type.id === attribute_value.attributable.field_type_id)[0].slug === 'select_field'
                            && attribute_value.attributable.selected_items
                            && attribute_value.attributable.selected_items.length > 0) &&
                            <div>
                            {attribute_value.attributable.selected_items.map((selected_item, selected_item_index)=>
                              {
                                return(
                                  <span key={selected_item_index}>
                                    {selected_item.label}
                                    {selected_item_index < (attribute_value.attributable.selected_items.length - 1) &&
                                    <span>, </span>
                                    }
                                  </span>
                                )
                              })
                            }
                            </div>
                          }
                          {
                            (field_types.filter(field_type =>
                            field_type.id === attribute_value.attributable.field_type_id)[0].slug === 'number_range_field') &&
                            <div>
                              {attribute_value.attribute.range.prefix !== null &&
                              <span>{attribute_value.attribute.range.prefix}</span>
                              }
                              {attribute_value.attributable.value}
                              {attribute_value.attribute.range.suffix !== null &&
                              <span>{attribute_value.attribute.range.suffix}</span>
                              }
                            </div>
                          }

                      </Col>
                    </Row>
                  }
                  </div>

                  )
                  })
                }
                </Col>
                {(section_index+1 !== sections.length) &&
                <Col xs="12">
                  <hr/>
                </Col>
                }

                </Row>

              )
            })
          }
          </div>
          </div>

          {show_section_aside_bar &&
          <div className="preview-footer flex-column pl-3 pr-3">

            <ActionUpdateButton
              block={true}
              size="md"
              onClick={()=>section_data.handleSaveSections()}
              saved={saved}
              label={(saved)?translate("Saved"):translate("Save all sections")}
              disabled={!changed || loading || (alert != null) || errorBag.field_validation_messages.length > 0 }
              loading={loading} />

              {progress.total > 0 &&
              <div className="progress-wrapper mt-3">
                <Progress
                color="success"
                value={(parseInt(progress.completed)/parseInt(progress.total))*parseInt(100)}
                className="mt-2">
                {progress.completed}/{progress.total}
                </Progress>
              </div>
              }
          </div>
          }
        </aside>
    );
  }
}



function mapStateToProps(state) {
    return {
        translate: getTranslate(state.localize),
        languages: getLanguages(state.localize),
        activeLanguage: getActiveLanguage(state.localize).code,
        loading: state.http_request.sending,
        alert: state.http_request.response,
        section_data:state.layout.section_aside_bar_data,
        show_section_aside_bar:state.layout.show_section_aside_bar
    };
}

SectionsMobilePreviewAsideBlock = connect(mapStateToProps)(SectionsMobilePreviewAsideBlock);
export {SectionsMobilePreviewAsideBlock};
